/* make the customizations */
$primary: #cd243b;
$danger: #ff4136;
$light: white;
$enable-rounded: false;
$body-color: #111827;
$dark: #111827;

/* Accordion theme */
$accordion-border-width: 0;
$accordion-button-focus-border-color: transprent;
$accordion-button-focus-box-shadow: none;
$accordion-button-color: $body-color;
$accordion-button-active-color: mix(black, $body-color, 10%);
$accordion-button-active-bg: mix(white, $body-color, 95%);

/* Modal theme */
$modal-footer-border-width: 0;
$modal-header-border-width: 0;

$btn-border-radius: 5px;
/* Form theme */
$input-border-radius: 6px;

$font-family-sans-serif: 'Outfit',
  // Cross-platform generic font family (default user interface font)
  system-ui,
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Windows
  'Segoe UI',
  // Android
  Roboto,
  // Basic web fallback
  'Helvetica Neue',
  Arial,
  // Linux
  'Noto Sans',
  'Liberation Sans',
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

.btn {
  border-radius: $btn-border-radius;
}

.header {
  background-color: $body-color;
}

.main-wrapper {
  width: 100%;
  min-height: calc(100vh - 6rem);
  display: flex;
  justify-content: center;
}

.loader-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.loader-text {
  margin-left: 5px;
  font-weight: 500;
}

.light_on_dark {
  color: white;
}

.avatar-container {
  color: #111827;
}

.avatar {
  background-color: #81e6d3;
  display: inline-flex;
  justify-content: center;
  color: $body-color;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  align-items: center;
}

.avatar-img {
  display: inline-flex;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
}

h1 {
  color: #00201c;
}

p {
  font-size: 1rem;
  font-weight: 400;
}

.btn-primary:focus {
  background-color: #cd243b;
}

.navbar-container {
  padding: 0.25rem 1.5rem 0.25rem 3rem;
}

.dropdown-toggle,
.dropdown-toggle:hover {
  color: white;
}

.dropdown-toggle::after {
  vertical-align: middle;
  transition: 0.3s;
  color: #111827;
}

.dropdown-toggle[aria-expanded='true']:after {
  transform: rotate(180deg);
}

.dropdown-item {
  padding: 0.5rem 1rem;
}

.dropdown-item:active {
  background-color: #f2f3f4;
  color: $body-color;
}

.nav-link {
  padding: 0.5rem 0rem;
}

.dropdown-menu {
  border-radius: 2px;
}

.style-quiz-container {
  padding-top: 10px;
}

.form-group {
  margin-top: 0rem;
}

.navbar--quiz-navigation {
  padding-top: 1.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.custom-options-group {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.custom-option {
  cursor: pointer;
  margin: 0.5rem 0.5rem 0.5rem;
  font-weight: 500;
}

.custom-radio-buttons {
  width: 100%;
  min-height: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.custom-radio-buttons input {
  display: none;
}

.custom-option input[type='radio']:checked + .radio-btn {
  background-color: #008586;
  border-color: #008586;
  color: white;
}

.custom-option input[type='checkbox']:checked + .checkbox-btn {
  background-color: #008586;
  border-color: #008586;
  color: white;
}

.mw-200 {
  width: 200px;
}

.radio-btn {
  padding: 0 1rem;
  width: 100%;
  min-width: 3.25rem;
  height: 3.25rem;
  border-radius: 30px;
  border: 1px solid $body-color;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.checkbox-btn {
  padding: 0 1rem;
  min-width: 3.25rem;
  height: 3.25rem;
  border: 1px solid $body-color;
  border-radius: 30px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.input-form-row {
  justify-content: center;
}

.input-form-row .col {
  max-width: 8rem;
}

.input-form-control {
  padding-right: 0;
  text-align: center;
  height: 3rem;
  border: none;
  border-bottom: 1px solid $body-color;
  background-color: #f2f3f4;
  color: $body-color;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-form-control:focus {
  border: 1px solid #f2f3f4;
  border-bottom: 1px solid #008586;
  box-shadow: none;
}

.input-group-text-custom {
  background-color: white;
  border: none;
  color: $body-color;
  padding: 0.375rem 0.375rem;
}

@media screen and (min-width: 320px) {
  .primary-action-btn {
    width: 250px;
  }
}

.primary-action-btn {
  border: 2px solid $primary;
  margin: 32px auto 20px;
  font-weight: 500;
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-link {
  border: none;
}

.navbar-light .navbar-nav .nav-link {
  color: #d9d9d9;
}

.nav-tabs .nav-link {
  font-weight: 500;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: white;
  border: none;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #25282d;
}

.nav-tabs .nav-link.active:hover {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #25282d;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.loggedin-header-nav-section {
  display: flex;
  align-items: center;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

@media screen and (min-width: 560px) {
  .loggedin-header-nav-section {
    flex-grow: 1;
    flex-basis: 100%;
  }
}
.navsection-first-child {
  justify-content: flex-start;
}

.navsection-middle-child {
  justify-content: center;
}

.navsection-last-child {
  justify-content: flex-end;
}
