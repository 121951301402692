.first-child {
  text-align: left;
}

.custom {
  color: black !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.header-action-btn {
  font-weight: 500;
}

.navbar-nav {
  align-items: center;
}

.user_default_avatar {
  position: relative;
  height: 30%;
  width: 30%;
}

@media screen and (max-width: 1352px) {
  .first-child {
    width: 20%;
  }
}

@media screen and (max-width: 991px) {
  .my-1rem {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
