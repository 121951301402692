$footer_bg: #121212;

.footer {
  max-width: 100%;
  background-color: $footer_bg;
  color: #eeeeee;
  position: sticky;
}

.logo {
  margin-bottom: 30px;
}

.main-row {
  padding: 3rem 2rem;
}

.slogan-text {
  margin-bottom: 50px;
}

.col-heading {
  padding-left: 0;
  padding-right: 0;
  text-align: start;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.second-col {
  text-align: start;
  color: white !important;
}

.icon {
  width: 2rem;
  height: 1.8rem;
  margin: 0rem 0.4rem;
}

.copyright-text {
  padding: 1rem 0;
  margin-top: 0;
}
.newsletter_input {
  border-top-left-radius: 8px 8px;
  border-bottom-left-radius: 8px 8px;
}

.newsletter_subscribe_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media (max-width: 992px) {
  .main-row {
    padding: 3rem 2rem 1rem 2rem;
  }

  .col-heading {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .second-col {
    text-align: center;
  }
}
