.fact-container {
  background-color: #ffef5f;
  padding: 5rem 0;
}

.why-join-dms-container {
  padding: 5rem 0;
  background-color: #161616;
  color: white;
}

.why-dms-container-heading {
  color: #33fde3;
}

.why_dms_container_icon {
  width: 3rem;
  text-align: start;
}

.ready-to-partner-container {
  padding: 6rem 0;
}

.help-container {
  background-color: #f9fafb;
  padding: 4rem 0;
}

.landing-page-cta-btn {
  max-width: 306px;
}

.landing-page-cta-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 01rem;
}

.onboarding-google-form {
  border: 0px;
  margin-bottom: 5rem;
}
